<template>
  <div class="px-7 pt-1 py-6">
    <div>
      <inline-button-input
        v-model="globalSearchSubmissionId"
        container-class="mb-5"
        placeholder="Enter Submission Id..."
        :btn-props="{
          text: 'Search',
          variant: 'btn-primary',
        }"
        :input-props="{
          containerClass: 'flex items-center max-w-md w-full'
        }"
        @click="executeSubmissionSearch()"
      >
        <template #label>
          <label class="text-base text-custom-gray-7 mr-3 truncate flex-shrink-0">Submission ID</label>
        </template>
      </inline-button-input>
    </div>
    <brand-assurance-navigation-tabs />
    <div
      class="border border-custom-gray-8"
      :class="{
        'mb-8': openedTabCount > 0 && openedTabCount <= 8,
        'mb-16': openedTabCount > 8 && openedTabCount <= 16,
        'mb-32': openedTabCount > 16,
      }"
    >
      <router-view />
    </div>
  </div>
  <teleport to="body">
    <upload-status />
  </teleport>
</template>

<script>
import { computed, defineAsyncComponent, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import useSubmissionForm from '@/components/brand-assurance-submission-form/submissionForm.js';
import useSubmissionFormModal from '@/components/brand-assurance-submission-form/submissionFormModal.js';

export default {
    name: 'BrandAssurance',

    components: {
        BrandAssuranceNavigationTabs: defineAsyncComponent(() => import('@/components/brand-assurance-navigation-tabs/BrandAssuranceNavigationTabs.vue')),
        InlineButtonInput: defineAsyncComponent(() => import('@/components/InlineButtonInput.vue')),
        UploadStatus: defineAsyncComponent(() => import('@/components/brand-assurance-submission-form/uploadStatusPopUp.vue'))
    },

    setup () {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const { submissionFilters, globalSearchSubmissionId } = useSubmissionForm();
        const { submissionsTabList } = useSubmissionFormModal();

        const searchedSubmissionId = computed(() => store.getters['baSubmissions/getSearchedSubmissionId']);

        watch(
            () => submissionFilters.currentStep,
            () => {
                if (submissionFilters.currentStep !== 'Personal Tasks') {
                    globalSearchSubmissionId.value = '';
                    if (!globalSearchSubmissionId.value) store.dispatch('baSubmissions/updateSearchedSubmissionId', { searchedSubmissionId: globalSearchSubmissionId.value });
                }
            }
        );

        watch(
            () => route.name,
            () => {
                if (route.name !== 'BAAdvanceSearch') {
                    globalSearchSubmissionId.value = '';
                }
            }
        );

        const executeSubmissionSearch = () => {
            if (globalSearchSubmissionId.value !== '') {
                store.dispatch('baSubmissions/updateSearchedSubmissionId', { searchedSubmissionId: globalSearchSubmissionId.value });
                if (route.name !== 'BAAdvanceSearch') router.push({ name: 'BAAdvanceSearch' });
            }
        };

        const openedTabCount = computed(() => {
            if (!submissionsTabList || !submissionsTabList.value || !submissionsTabList.value.length) return 0;
            else return submissionsTabList.value.length;
        });

        return {
            globalSearchSubmissionId,
            executeSubmissionSearch,
            searchedSubmissionId,
            openedTabCount
        };
    }
};
</script>
